@import 'brand-colors';
@import '../../public/branding/default/icp';

// Variable overrides for the Bryant branded site

$primaryExtraLight: map-get($primaryExtraLightBranded, 'default');
$primaryLight: map-get($primaryLightBranded, 'default');
$primaryLightTint1: map-get($primaryLightTint1Branded, 'default');
$primaryLightTint2: map-get($primaryLightTint2Branded, 'default');
$primaryLightTint3: map-get($primaryLightTint3Branded, 'default');
$primaryLightTint4: map-get($primaryLightTint4Branded, 'default');
$primary: map-get($primaryBranded, 'default');
$primaryTint: map-get($primaryTintBranded, 'default');
$primaryTransparent: map-get($primaryTransparentBranded, 'default');
$primaryDark: map-get($primaryDarkBranded, 'default');
$primaryExtraDark: map-get($primaryExtraDarkBranded, 'default');
$secondaryLight: map-get($secondaryLightBranded, 'default');
$secondaryDark: map-get($secondaryDarkBranded, 'default');
$accent: map-get($accentBranded, 'default');

$brand: map-get($brandBranded, 'default');
$highlight: map-get($highlightBranded, 'default');
$illustrations: map-get($illustrationsBranded, 'default');
$heroGradientStart: map-get($heroGradientStartBranded, 'default');
$heroGradientEnd: map-get($heroGradientEndBranded, 'default');
$lightPrimary: map-get($lightPrimaryBranded, 'default');
$mediumPrimary: map-get($mediumPrimaryBranded, 'default');
$topographicLinesDark: map-get($topographicLinesDarkBranded, 'default');
$topographicLinesLight: map-get($topographicLinesLightBranded, 'default');

$headerSearchBar: $primaryLightTint3;
$headerSearchHover: $primaryLightTint4;
