$defaultButton: (
    'color': $primaryBranded,
    'background': $primaryLightTint1Branded,
    'hoverColor': $primaryExtraDarkBranded,
    'hoverBackground': $primaryLightTint2Branded,
    'hoverIcon': $primaryLightBranded,
    'focusColor': $primaryBranded,
    'focusBackground': $primaryLightTint1Branded,
    'focusOutline': $primaryBranded
);

$primaryButton: (
    'color': $white,
    'background': $primaryBranded,
    'hoverColor': $white,
    'hoverBackground': $primaryExtraDarkBranded,
    'hoverIcon': $primaryLightBranded,
    'focusColor': $white,
    'focusBackground': $primaryExtraDarkBranded,
    'focusOutline': $primaryBranded
);

$secondaryButton: (
    'color': $primaryBranded,
    'background': $white,
    'hoverColor': $primaryExtraDarkBranded,
    'hoverBackground': $white,
    'hoverIcon': $primaryExtraDarkBranded,
    'focusColor': $primaryBranded,
    'focusBackground': $white,
    'focusOutline': $primaryBranded
);

$tertiaryButton: (
    'color': $primaryExtraLightBranded,
    'background': $primaryLightTint3Branded,
    'hoverColor': $white,
    'hoverBackground': $primaryLightTint4Branded,
    'hoverIcon': $primaryLightBranded,
    'focusColor': $primaryExtraLightBranded,
    'focusBackground': $primaryLightTint3Branded,
    'focusOutline': $accentBranded,
);

$linkColor: map-get($primaryBranded, 'default');
$linkHover: map-get($primaryLightBranded, 'default');
